import Image from "next/image"
import React from "react"
import { Facebook, Instagram, X, YouTube } from "@mui/icons-material"
import { Box, Icon, IconButton, useMediaQuery, useTheme } from "@mui/material"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import TikTokIcon from "../assests/TikTokIcon"

interface SocialAccount {
  icon: React.ReactElement
  link: string
  label: string
}

const SOCIAL_ACCOUNTS: SocialAccount[] = [
  {
    icon: <Instagram />,
    link: "https://www.instagram.com/coastlineacademy",
    label: "Instagram Link",
  },
  { icon: <X />, link: "https://x.com/drivecoastline", label: "X Link" },
  {
    icon: <Facebook />,
    link: "https://www.facebook.com/coastlineacademy",
    label: "Facebook Link",
  },
  {
    icon: <YouTube />,
    link: "https://www.youtube.com/channel/UCPruykTxAWpwh8i1GK-U1kA",
    label: "YouTube Link",
  },
  {
    icon: <TikTokIcon />,
    link: "https://www.tiktok.com/@coastline.academy",
    label: "TikTok Link",
  },
]

const Footer = (): React.ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width:1350px)")

  const SCHOOL_LICENSE_NUMBERS = React.useMemo(
    () => [
      {
        label: `California ${t("License")} E2115`,
        href: "https://www.dmv.ca.gov/wasapp/olinq2/display.do?submitName=Display&ol=E2115~E~E2115~00",
      },
      {
        label: `Colorado ${t("Certificate")} 9906`,
      },
      {
        label: `Texas ${t("License")} C3371`,
      },
      {
        label: `Las Vegas ${t("License")} #PRDS00051916`,
      },
      {
        label: `Reno ${t("License")} #PRDS00051767`,
      },
      {
        label: `Missouri ${t("License")} #FL1436534`,
      },
      {
        label: `Indianapolis, IN ${t("License")} PDF`,
        href: "/l-assets/coastline_academy_indianapolis_license.pdf",
      },
      {
        label: `Valparaiso, IN ${t("License")} PDF`,
        href: "/l-assets/coastline_academy_valparaiso_license.pdf",
      },
      {
        label: `Illinois ${t("License")} #9505`,
      },
    ],
    [t],
  )

  return (
    <footer>
      <Box
        sx={{
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          p: 4,
          py: 10,
          "& a": {
            color: theme.palette.primary.contrastText,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent={isMobile ? "flex-start" : "space-around"}
          flexWrap="wrap"
          flexDirection={isMobile ? "column" : "row"}
        >
          <Box sx={{ m: 2 }}>
            <Image
              src="https://res.cloudinary.com/coastline-academy/image/upload/c_fill,g_faces,h_80,w_80/q_80/v1601234567/logos/Coastline_Logomark_Teal_square_xgtl6b.png"
              alt="Coastline Academy Logo"
              width={80}
              height={80}
            />
            <ul
              style={{
                marginTop: 20,
                marginBottom: 20,
                listStyle: "none",
                padding: 0,
              }}
            >
              {SCHOOL_LICENSE_NUMBERS.map((license) => (
                <li key={license.label}>
                  {license.href ? (
                    <a
                      href={license.href}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {license.label}
                    </a>
                  ) : (
                    <span>{license.label}</span>
                  )}
                </li>
              ))}
            </ul>
            &copy; Coastline Academy
          </Box>
          <Box sx={{ m: 2 }}>
            <h3 style={{ marginTop: 0, color: theme.palette.secondary.light }}>
              Who We Are
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link href="/article/about-coastline-academy">About Us</Link>
              </li>
              <li>
                <Link href="/careers">Careers</Link>
              </li>
              <li>
                <Link href="/contact">Contact Us</Link>
              </li>
              <li>
                <Link href="/content/terms-of-service">Terms of Service</Link>
              </li>
              <li>
                <Link href="/content/privacy-notice">Privacy</Link>
              </li>
              <li>
                <Link href="/affiliate-signup">Affiliates</Link>
              </li>
            </ul>
          </Box>
          <Box sx={{ m: 2 }}>
            <h3 style={{ marginTop: 0, color: theme.palette.secondary.light }}>
              What We Do
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link href="/courses">Drivers Ed</Link>
              </li>
              <li>
                <Link href="/book">Driving Lessons</Link>
              </li>
              <li>
                <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                  <li>
                    {"└ "}
                    <Link href="/coastline-academy-driving-school-reviews">
                      Reviews
                    </Link>
                  </li>
                  <li>
                    {"└ "}
                    <Link href="/locations">Locations</Link>
                  </li>
                  <li>
                    {"└ "}
                    <Link href="/pricing">Pricing</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link href="/articles">Articles</Link>
              </li>
              <li>
                <Link href="/how-to-get-drivers-license">
                  How to Get a License
                </Link>
              </li>
            </ul>
          </Box>
          <Box sx={{ m: 2 }}>
            <h3 style={{ marginTop: 0, color: theme.palette.secondary.light }}>
              Why We Exist
            </h3>
            <p style={{ maxWidth: 500 }}>
              We&apos;re on a mission to eradicate car crashes. We do this by
              helping people become safe, confident drivers for life.
            </p>
            <h3 style={{ color: theme.palette.secondary.light }}>
              States Where We Operate
            </h3>
            <ul style={{ listStyle: "none", padding: 0 }}>
              <li>
                <Link href="/state/ca">California</Link>
              </li>
              <li>
                <Link href="/state/co">Colorado</Link>
              </li>
              <li>
                <Link href="/state/il">Illinois</Link>
              </li>
              <li>
                <Link href="/state/in">Indiana</Link>
              </li>
              <li>
                <Link href="/state/mo">Missouri</Link>
              </li>
              <li>
                <Link href="/state/nv">Nevada</Link>
              </li>
              <li>
                <Link href="/state/tx">Texas</Link>
              </li>
            </ul>
          </Box>
        </Box>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {SOCIAL_ACCOUNTS.map((account, i) => (
            <IconButton
              key={i}
              href={account.link}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={account.label}
              size="large"
            >
              <Icon color="inherit">{account.icon}</Icon>
            </IconButton>
          ))}
        </div>
      </Box>
    </footer>
  )
}

export default Footer
